<template>
  <div class="authenticationSuccess-wrap">
    <div class="title">认证成功</div>
    <div class="img-wrap">
      <img src="@/assets/imgs/authenticationSuccess.png" alt />
    </div>
    <!-- <template v-if="type === 100">
      <div class="btn" @click="goBackSign">返回签署</div>
    </template> -->
    <template v-if="type !== 100">
      <section class="des">
        <template v-if="type !== 2">
          <p>
            请您选择以下方式继续签署认证授权书
            <br />第一种：点击下方按钮在手机上完成签署
            <br />第二种：请在【电脑端】刷新页面，点击开始签署
            <br />
          </p>
          <p style="text-align: center;">请您点击下方按钮在手机上完成签署</p>
        </template>
        <template v-else>
          <p style="text-align: center;">
            如果您在【电脑端】操作，请在【电脑端】刷新页面，继续操作
          </p>
        </template>
      </section>
      <!-- <div class="btn" v-if="type !== 2" @click="goUrl">签署认证授权书</div> -->
      <!-- <van-button
        v-else-if="$route.query.redirectUrl || type !== 100"
        class="back-button"
        type="primary"
        @click="handleBack"
      >
        返回
      </van-button> -->
    </template>
    <van-button type="primary" class="back-button">
      自动跳转（{{ num }}）
    </van-button>
  </div>
</template>
<script>
  import ApiSign from '@/api/addSign'
  import { Dialog, Toast } from 'vant'

  export default {
    data() {
      return {
        type: this.$store.state.creditType,
        num: 3,
        timer: null,
      }
    },
    created() {
      // if (this.type !== 2) {
      //   return
      // }
      // this.$store.dispatch('fetchUserInfo')
    },
    mounted() {
      console.log('type', this.type)
      const that = this
      that.timer = setInterval(() => {
        that.num -= 1
        if (that.num <= 0) {
          clearInterval(that.timer)
          that.handleBack()
        }
      }, 1000)
    },
    methods: {
      goUrl() {
        this.$router.replace('/signCreaditBook')
      },
      // 加入签署实名认证或者签署时的跳转
      goBackSign() {
        this.$store.dispatch('fetchUserInfo').then(() => {
          const url = this.$store.state.signUrl
          const url2 = this.$store.state.comSignUrl
          // this.$router.replace(url)
          if (url === '/addSignNoConfirm' || url2 === '/addSignNoConfirm') {
            this.addSign()
          } else {
            this.$router.replace(url)
          }
        })
      },

      handleBack() {
        // const url2 = this.$store.state.comSignUrl
        if (this.type === 100) {
          console.log(1)
          this.goBackSign()
        } else if (this.type !== 2) {
          console.log(2)
          this.goUrl()
        } else {
          console.log(3)
          const { redirectUrl } = this.$route.query
          if (redirectUrl && redirectUrl !== 'undefined') {
            this.$router.replace(redirectUrl)
          } else {
            this.$router.replace({
              name: 'My',
            })
          }
        }
      },
      // 如果从加入签署页过来，直接加入签署进入详情页
      async addSign() {
        this.toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
          loadingType: 'spinner',
        })
        ApiSign.joinSign({
          partnerId: this.$store.state.sign.partnerId,
          comId: this.$store.state.user.userInfo.comId,
        })
          .then(async res => {
            await this.$store.dispatch('fetchUserInfo')
            const { msg, status, data } = res
            if (status === 200) {
              const { businessStatus } = data
              // 0 草稿 1未完成 2 已完成 3已作废
              if (businessStatus === 3) {
                this.$router.replace({
                  path: '/signComplete',
                })
                return
              }
              this.$router.replace({
                path: '/signdetail',
                query: {
                  businessId: data.businessId,
                },
              })
              return
            }
            Dialog.alert({
              title: '提示',
              message: msg,
            }).then(() => {})
          })
          .finally(() => {
            this.toast.clear()
          })
      },
    },
  }
</script>
<style lang="less" scoped>
  .authenticationSuccess-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 16px;
    .img-wrap {
      margin: 36px auto 33px;
      width: 120px;
      height: 120px;
      img {
        width: 120px;
        height: 120px;
      }
    }
    .title {
      height: 24px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(17, 26, 52, 1);
      line-height: 24px;
      text-align: center;
      margin-bottom: 20px;
    }
    .des {
      width: 264px;
      margin: 0 auto;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(103, 114, 131, 1);
      line-height: 24px;
      margin-bottom: 64px;
    }
    .btn {
      width: 327px;
      height: 44px;
      background: rgba(22, 118, 255, 1);
      box-shadow: 0px 6px 20px 0px rgba(78, 128, 245, 0.16);
      border-radius: 4px;
      margin: 0 auto;
      text-align: center;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 44px;
    }

    .back-button {
      width: 340px;
      position: fixed;
      bottom: 16px;
    }
  }
</style>
